import React from 'react';
import size from 'lodash/size';

import {
  UserID,
  UsersTabType,
  UserUUID
} from '../../../../../../../users/usersTypes';
import { I18nText } from '../../../../../../../../types';

import { useRecentlySelectedUsersList } from './hooks/useRecentlySelectedUsersList';

import { CheckableUsersList } from '../CheckableUsersList';
import { CheckableUsersListItemUserType } from '../CheckableUsersListItem';

import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../../../../../helpers/Translate';

import { words } from '../../../../../../../../locales/keys';

interface RecentlySelectedUsersListProps {
  selectedUserIds?: UserID[];
  i18nNoResultsMessage?: string;
  invitedUserUuids?: UserUUID[];
  onCheck?: (user: CheckableUsersListItemUserType, checked: boolean) => void;
  onCheckAll?: (
    users: CheckableUsersListItemUserType[],
    checked: boolean
  ) => void;
  tab: UsersTabType;
  alreadySelectedI18nTitle?: I18nText;
}

function RecentlySelectedUsersList({
  selectedUserIds = [],
  i18nNoResultsMessage = words.noResultsYet,
  invitedUserUuids,
  onCheck,
  onCheckAll,
  tab,
  alreadySelectedI18nTitle
}: RecentlySelectedUsersListProps) {
  const {
    inviteUserSearchesError,
    inviteUserSearches,
    inviteUserSearchesFetched,
    deleteInviteUserSearchError,
    deleteInviteUserSearch
  } = useRecentlySelectedUsersList({ tab });

  return (
    <div data-id="users-list-recently-selected" className="mt-4">
      <LoadingSkeleton count={2} loaded={inviteUserSearchesFetched}>
        {size(inviteUserSearches) === 0 ? (
          <div className="text-center my-5">
            <Translate id={i18nNoResultsMessage} />
          </div>
        ) : (
          <>
            <p className="font-light text-xs uppercase mb-px">
              <Translate id={words.recentlySelected} />
            </p>
            <CheckableUsersList
              invitedUserUuids={invitedUserUuids}
              onCheck={onCheck}
              onCheckAll={onCheckAll}
              onRemoveRecentUser={deleteInviteUserSearch}
              selectedUserIds={selectedUserIds}
              users={inviteUserSearches?.map(({ uuid, searchedUser }) => ({
                ...searchedUser,
                recentUuid: uuid
              }))}
              alreadySelectedI18nTitle={alreadySelectedI18nTitle}
            />
          </>
        )}
        <AlertMessage
          message={inviteUserSearchesError || deleteInviteUserSearchError}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default RecentlySelectedUsersList;
